import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    treatments: {
      loading: false,
      data: [],
    },
    basicTreatments: {
      loading: false,
      data: [],
    },
    treatmentLengths: [],
    treatment: null,
    treatmentLoading: false,
    createTreatmentLoading: false,
    updateTreatmentLoading: false,
    deleteTreatmentLoading: null,
  },
  mutations: {
    SET_TREATMENTS_LOADING: function (state, payload) {
      state.treatments.loading = payload;
    },
    SET_TREATMENTS_DATA: function (state, payload) {
      state.treatments.data = payload;
    },
    SET_TREATMENT: function (state, payload) {
      state.treatment = payload;
    },
    SET_TREATMENT_LOADING: function (state, payload) {
      state.treatmentLoading = payload;
    },
    SET_CREATE_TREATMENT_LOADING: function (state, payload) {
      state.createTreatmentLoading = payload;
    },
    SET_UPDATE_TREATMENT_LOADING: function (state, payload) {
      state.updateTreatmentLoading = payload;
    },
    SET_DELETE_TREATMENT_LOADING: function (state, payload) {
      state.deleteTreatmentLoading = payload;
    },
    SET_TREATMENT_LENGTHS: function (state, payload) {
      state.treatmentLengths = payload;
    },
    SET_BASIC_TREATMENTS_LOADING: function (state, payload) {
      state.basicTreatments.loading = payload;
    },
    SET_BASIC_TREATMENTS_DATA: function (state, payload) {
      state.basicTreatments.data = payload;
    },
  },
  actions: {
    getTreatments: function ({ commit }, payload) {
      const vm = this;
      commit("SET_TREATMENTS_LOADING", true);
      const filters = [];
      if (payload) {
        if (payload.search) {
          filters.push(`search=${payload.search}`);
        }
        if (payload.serviceId) {
          filters.push(`service_id=${payload.serviceId}`);
        }
        if (typeof payload?.availableForBooking !== "undefined") {
          filters.push(`available_for_booking=${payload.availableForBooking}`);
        }
      }
      let url = `treatments`;
      if (filters.length) {
        url += `?${filters.join("&")}`;
      }
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            commit("SET_TREATMENTS_DATA", res.data.data);
            commit("SET_TREATMENTS_LOADING", false);
            resolve(res.data);
          },
          (err) => {
            commit("SET_TREATMENTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getTreatment: function ({ commit }, treatmentId) {
      const vm = this;
      commit("SET_TREATMENT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`treatments/${treatmentId}`).then(
          (res) => {
            commit("SET_TREATMENT", res.data.data);
            commit("SET_TREATMENT_LOADING", false);
            resolve(res.data?.data);
          },
          (err) => {
            commit("SET_TREATMENT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message?.error ||
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    createTreatment: function ({ commit }, payload) {
      const vm = this;
      commit("SET_CREATE_TREATMENT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.post(`treatments`, payload).then(
          () => {
            commit("SET_CREATE_TREATMENT_LOADING", false);
            resolve(true);
          },
          (err) => {
            commit("SET_CREATE_TREATMENT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    updateTreatment: function ({ commit }, payload) {
      const vm = this;
      commit("SET_UPDATE_TREATMENT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.put(`treatments/${payload.treatmentId}`, payload).then(
          () => {
            commit("SET_UPDATE_TREATMENT_LOADING", false);
            resolve(true);
          },
          (err) => {
            commit("SET_UPDATE_TREATMENT_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteTreatment: function ({ commit }, treatmentId) {
      const vm = this;
      commit("SET_DELETE_TREATMENT_LOADING", treatmentId);
      return new Promise((resolve) => {
        return vm.$http.delete(`treatments/${treatmentId}`).then(
          () => {
            commit("SET_DELETE_TREATMENT_LOADING", null);
            Swal.fire("Deleted!", "Treatment has been deleted.", "success");
            resolve(true);
          },
          (err) => {
            commit("SET_DELETE_TREATMENT_LOADING", null);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    generateTreatmentLengths: function ({ commit }) {
      const lengths = Array.from({ length: 12 * 12 + 1 }, (_, i) => {
        if (i === 0) {
          return {
            label: "Select a duration",
            value: null,
          };
        }
        const hour = Math.floor(i / 12);
        const minutes = (i % 12) * 5;
        return {
          label: `${hour ? `${hour} hour` : ""}${
            hour && minutes ? " and " : ""
          }${minutes ? `${minutes} minutes` : ""}`,
          value: i * 5,
        };
      });

      commit("SET_TREATMENT_LENGTHS", lengths);
    },
    getBasicTreatments: function ({ commit }) {
      const vm = this;
      commit("SET_BASIC_TREATMENTS_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get(`basic-treatments`).then(
          (res) => {
            commit("SET_BASIC_TREATMENTS_DATA", res.data.data);
            commit("SET_BASIC_TREATMENTS_LOADING", false);
            resolve(res.data?.data);
          },
          (err) => {
            commit("SET_BASIC_TREATMENTS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text:
                  err?.data?.error?.message ||
                  err?.data?.message ||
                  "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
  },
};
