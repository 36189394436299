import Gleap from "gleap";

export default {
  install(Vue) {
    if (!window.__gleapInitialized) {
      Gleap.initialize("0iK3FAEyt9KwZu0dvaP5gnmCRzmlVzce");
      window.__gleapInitialized = true;
      window.Gleap = Gleap;
    }

    Vue.prototype.$gleap = Gleap;
  },
};

export const identifyUser = function (user, companyProfile) {
  if (window.Gleap) {
    if (user.gleap_hash && user.gleap_id) {
      window.Gleap.identify(
        user.gleap_id,
        {
          name: `${user.first} ${user.last}`,
          email: user.email,
          phone: user.phone_number,
          companyId: companyProfile.name,
          companyName: companyProfile.name,
          customData: {
            role: user.isAdmin
              ? "Super Admin"
              : user.isManagingAdmin
              ? "Managing Admin"
              : user.isProvider
              ? "Provider"
              : user.isPatient
              ? "Patient"
              : "User",
            status: user.status,
          },
        },
        user.gleap_hash
      );
    }
  } else {
    console.log("Gleap not initialized");
  }
};

export const clearUserIdentity = function () {
  if (window.Gleap) {
    window.Gleap.clearIdentity();
  } else {
    console.log("Gleap not initialized");
  }
};
