import * as dayjs from "dayjs";
import { startCase } from "lodash";
import isBetween from "dayjs/plugin/isBetween";
dayjs.extend(isBetween);

export default {
  prettyDate: function (date, format) {
    if (typeof format == "undefined") {
      format = "ddd, MMM D YYYY, h:mm A";
    }
    let myDate = dayjs(date).format(format);
    return myDate;
  },
  dateIsBetween(startDate, endDate, targetDate) {
    return dayjs(targetDate).isBetween(startDate, endDate, "day", "[]");
  },
  extract: function ([beg, end]) {
    const matcher = new RegExp(`${beg}(.*?)${end}`, "gm");
    const normalise = (str) => str.slice(beg.length, end.length * -1);
    return function (str) {
      return str.match(matcher).map(normalise);
    };
  },
  textToUpperCase: function (status) {
    return status ? startCase(status) : "N/A";
  },
  getFileTypeByExtension: function (extension) {
    if (extension == "xls" || extension == "xlsx") {
      return "excel";
    } else if (extension == "ppt" || extension == "pptx") {
      return "powerpoint";
    } else if (extension == "doc" || extension == "docx") {
      return "word";
    } else if (extension == "pdf") {
      return "pdf";
    } else if (["jpg", "jpeg", "png", "gif"].includes(extension)) {
      return "image";
    }
    return null;
  },
};
