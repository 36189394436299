import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    sharedRecordings: {
      loading: false,
      data: [],
    },
    notifications: {
      loading: false,
      countInfo: {
        loading: false,
        read: 0,
        unread: 0,
        total: 0,
      },
      pagination: {
        nextCursor: null,
        data: [],
      },
    },
  },
  mutations: {
    SET_SHARED_RECORDINGS_LOADING: (state, data) => {
      state.sharedRecordings.loading = data;
    },
    SET_SHARED_RECORDINGS_DATA: (state, data) => {
      state.sharedRecordings.data = data;
    },
    SET_NOTIFICATIONS_LOADING: (state, data) => {
      state.notifications.loading = data;
    },
    SET_NOTIFICATIONS_COUNT_LOADING: (state, data) => {
      state.notifications.countInfo.loading = data;
    },
    SET_NOTIFICATIONS_COUNT_DATA: (state, data) => {
      state.notifications.countInfo = data;
    },
    SET_NOTIFICATIONS_PAGINATION: (state, data) => {
      state.notifications.pagination = data;
    },
  },
  actions: {
    getSharedRecordings: function ({ commit }, payload) {
      commit("SET_SHARED_RECORDINGS_LOADING", true);
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http.get(`users/${payload.userId}/shared-recordings`).then(
          (res) => {
            commit("SET_SHARED_RECORDINGS_LOADING", false);
            commit("SET_SHARED_RECORDINGS_DATA", res.data.data);
            resolve(res);
          },
          (err) => {
            commit("SET_SHARED_RECORDINGS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    deleteSharedRecording: function (_, payload) {
      const vm = this;
      return new Promise((resolve) => {
        return vm.$http
          .post(`users/${payload.userId}/delete-shared-recording`, payload.data)
          .then(
            (res) => {
              resolve(res);
            },
            (err) => {
              if (!err.accessDenied) {
                Swal.fire({
                  title: "Error",
                  text: err?.data?.error?.message || "Something went wrong...",
                  icon: "error",
                });
              }
              resolve(false);
            }
          );
      });
    },
    getNotifications: function ({ commit, state }, payload) {
      const vm = this;
      let url = `users/notifications`;
      if (payload.limit) {
        url = `${url}?limit=${payload.limit}`;
      }
      if (payload.nextCursor) {
        url = `${url}&cursor=${payload.nextCursor}`;
      }
      if (payload.status) {
        url = `${url}&status=${payload.status}`;
      }
      if (!payload.noLoading) {
        commit("SET_NOTIFICATIONS_LOADING", true);
      }
      return new Promise((resolve) => {
        return vm.$http.get(url).then(
          (res) => {
            commit("SET_NOTIFICATIONS_LOADING", false);
            if (payload && payload.nextCursor) {
              commit("SET_NOTIFICATIONS_PAGINATION", {
                nextCursor: res.data.meta.next_cursor,
                data: [
                  ...state.notifications.pagination.data,
                  ...res.data.data,
                ],
              });
            } else if (payload && payload.limit) {
              commit("SET_NOTIFICATIONS_PAGINATION", {
                nextCursor: res.data.meta.next_cursor,
                data: res.data.data,
              });
            } else {
              commit("SET_NOTIFICATIONS_PAGINATION", {
                nextCursor: null,
                data: res.data.data,
              });
            }
            resolve(res);
          },
          (err) => {
            commit("SET_NOTIFICATIONS_LOADING", false);
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: err?.data?.error?.message || "Something went wrong...",
                icon: "error",
              });
            }
            resolve(false);
          }
        );
      });
    },
    getNotificationsCount: function ({ commit }) {
      let vm = this;
      commit("SET_NOTIFICATIONS_COUNT_LOADING", true);
      return new Promise((resolve) => {
        return vm.$http.get("users/notifications-count").then(
          (res) => {
            const data = res.data.data;
            commit("SET_NOTIFICATIONS_COUNT_DATA", {
              loading: false,
              read: data.read,
              unread: data.unread,
              total: parseInt(data.read || 0) + parseInt(data.unread || 0),
            });
            resolve(res.data.data);
          },
          () => {
            commit("SET_NOTIFICATIONS_COUNT_LOADING", false);
            resolve(false);
          }
        );
      });
    },
    updateNotificationStatus: function ({ commit, state }, { id, status }) {
      let vm = this;
      const stepValue = status ? 1 : -1;
      return new Promise(function (resolve) {
        vm.$http
          .put(`users/notifications/${id}/read-status`, { status })
          .then(() => {
            commit("SET_NOTIFICATIONS_COUNT_DATA", {
              ...state.notifications.countInfo,
              read: id
                ? state.notifications.countInfo.read + stepValue
                : state.notifications.countInfo.total,
              unread: id ? state.notifications.countInfo.unread - stepValue : 0,
              total: state.notifications.countInfo.total,
            });
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    addNewNotification: function ({ commit, state }, notification) {
      commit("SET_NOTIFICATIONS_PAGINATION", {
        nextCursor: state.notifications.pagination.nextCursor,
        data: [notification, ...state.notifications.pagination.data],
      });
      commit("SET_NOTIFICATIONS_COUNT_DATA", {
        loading: state.notifications.countInfo.loading,
        read: state.notifications.countInfo.read,
        unread: state.notifications.countInfo.unread + 1,
        total: state.notifications.countInfo.total + 1,
      });
    },
  },
};
