import Swal from "sweetalert2";
import router from "../router";

export default {
  namespaced: true,
  state: {
    data: {},
    printTransaction: {},
    isLoading: false,
    isSaving: false,
  },
  mutations: {
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    addTransactions: function (context, data) {
      if (data) {
        this.$http
          .post(
            `payments/transactions${
              data.user_id ? "?user_id=" + data.user_id : ""
            }`,
            {
              amount: data.amount,
            }
          )
          .then((res) => {
            if (res) {
              router.push({
                name: "transactions",
                params: { id: data.patient_id },
              });
            }
          })
          .catch((err) => {
            Swal.fire("Error", err.data?.error?.message, "error");
          });
      }
    },
    getTransactions: function (context, user_id) {
      context.commit("SET_STATE", {
        key: "isLoading",
        value: true,
      });
      this.$http
        .get(`payments/transactions${user_id ? "?user_id=" + user_id : ""}`)
        .then((res) => {
          context.commit("SET_STATE", {
            key: "data",
            value: res.data.data,
          });
          context.commit("SET_STATE", {
            key: "isLoading",
            value: false,
          });
        })
        .catch((err) => {
          Swal.fire("Error", err.data?.error?.message, "error");
        });
    },
    selectPrintTransaction: function (context, data) {
      if (data) {
        context.commit("SET_STATE", {
          key: "printTransaction",
          value: data,
        });
      }
    },
  },
};
