import Swal from "sweetalert2";

export default {
  namespaced: true,
  state: {
    numberOfUnreadTasks: 0,
    isLoading: false,
    data: [],
    pagination: {
      current: null,
      total: null,
      data: [],
    },
    draftData: [],
    draftPagination: {
      current: null,
      total: null,
      data: [],
    },
    taskTypes: {
      data: [],
      isLoading: false,
    },
  },
  mutations: {
    SET_UNREAD_TASKS: (state, tasks) => {
      state.numberOfUnreadTasks = tasks;
    },
    SET_PAGINATION: (state, data) => {
      state.pagination = data;
    },
    SET_DRAFT_PAGINATION: (state, data) => {
      state.draftPagination = data;
    },
    SET_DATA: (state, data) => {
      state.data = data;
    },
    SET_DRAFT_DATA: (state, data) => {
      state.draftData = data;
    },
    SET_STATE: (state, obj) => {
      state[`${obj.key}`] = obj.value;
    },
    SET_TYPES_STATE: (state, obj) => {
      state.taskTypes[`${obj.key}`] = obj.value;
    },
  },
  actions: {
    markAsRead: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put("tasks/" + id + "/read")
          .then(() => {
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    markAsUnread: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put("tasks/" + id + "/unread")
          .then(() => {
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    markAllAsRead: function ({ dispatch }) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put("tasks/mark-all-read")
          .then(() => {
            dispatch("getMyUnreadTasksCount");
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    decrementUnread: function ({ commit, state }) {
      if (state.numberOfUnreadTasks > 0) {
        commit("SET_UNREAD_TASKS", state.numberOfUnreadTasks - 1);
      }
    },
    incrementUnread: function ({ commit, state }) {
      commit("SET_UNREAD_TASKS", state.numberOfUnreadTasks + 1);
    },
    getMyUnreadTasksCount: function (context) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(`tasks/unread-count`)
          .then((res) => {
            context.commit("SET_UNREAD_TASKS", res.data.unread_count);
            return resolve(res.data.unread_count);
          })
          .catch((err) => {
            //eslint-disable-next-line
            console.log(err);
            return resolve();
          });
      });
    },
    markAsImportant: function (_, { id, status }) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put("tasks/" + id + "/important", { status })
          .then(() => {
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    getTasks: function ({ commit, state }, payload) {
      let url = "tasks";
      if (payload) {
        if (payload.limit) {
          url = `${url}?limit=${payload.limit}`;
        }
        if (payload.assigned_id) {
          url = `${url}&assigned_id=${payload.assigned_id}`;
        }
        if (payload.mine) {
          url = `${url}&mine=${payload.mine}`;
        }
        if (payload.tagged_in) {
          url = `${url}&tagged_in=${payload.tagged_in}`;
        }
        if (payload.resolved) {
          url = `${url}&resolved=${payload.resolved}`;
        }
        if (payload.read_status) {
          url = `${url}&read_status=${payload.read_status}`;
        }
        if (payload.important) {
          url = `${url}&important=${payload.important}`;
        }
        if (payload.priority) {
          url = `${url}&priority=${payload.priority}`;
        }
        if (payload.type) {
          url = `${url}&type=${payload.type}`;
        }
        if (payload.asc) {
          url = `${url}&asc=${payload.asc}`;
        }

        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.sent_tasks) {
          url = `${url}&sent_tasks=${payload.sent_tasks}`;
        }
        if (payload.start_date) {
          url = `${url}&start_date=${payload.start_date}`;
        }
        if (payload.end_date) {
          url = `${url}&end_date=${payload.end_date}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_STATE", { key: "isLoading", value: true });
      }

      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload.page) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [...state.pagination.data, ...res.data.data],
                });
              } else if (payload && payload.limit) {
                commit("SET_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_DATA", res.data);
              }
              commit("SET_STATE", { key: "isLoading", value: false });
              return resolve();
            }
            return resolve();
          })
          .catch((err) => {
            commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              console.log(err);
              return resolve();
            }
          });
      });
    },
    addReaction: function (_, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(
            `tasks/${payload.task_id}/add-${
              payload.isReply ? "reply-" : ""
            }reaction`,
            payload
          )
          .then(() => {
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    deleteReaction: function (_, payload) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .put(
            `tasks/${payload.task_id}/delete-${
              payload.isReply ? "reply-" : ""
            }reaction`,
            payload
          )
          .then(() => {
            return resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              return resolve(false);
            }
          });
      });
    },
    getDrafts: function ({ commit, state }, payload) {
      let url = "drafts";
      if (payload) {
        if (payload.limit) {
          url = `${url}?limit=${payload.limit}`;
        }
        if (payload.page) {
          url = `${url}&page=${payload.page}`;
        }
        if (payload.asc) {
          url = `${url}&asc=${payload.asc}`;
        }
      }
      if (!payload || !payload.page) {
        commit("SET_STATE", { key: "isLoading", value: true });
      }

      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .get(url)
          .then((res) => {
            if (res.status == 200) {
              if (payload.page) {
                commit("SET_DRAFT_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: [...state.draftPagination.data, ...res.data.data],
                });
              } else if (payload && payload.limit) {
                commit("SET_DRAFT_PAGINATION", {
                  total: res.data.meta.total,
                  current: res.data.meta.current_page,
                  data: res.data.data,
                });
              } else {
                commit("SET_DRAFT_DATA", res.data);
              }
              commit("SET_STATE", { key: "isLoading", value: false });
              return resolve();
            }
            return resolve();
          })
          .catch((err) => {
            commit("SET_STATE", { key: "isLoading", value: false });
            if (!err.accessDenied) {
              console.log(err);
              return resolve();
            }
          });
      });
    },
    deleteDraft: function (_, id) {
      let vm = this;
      return new Promise(function (resolve) {
        vm.$http
          .delete(`drafts/${id}`)
          .then(() => {
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    getTaskTypes: function (context, payload) {
      let vm = this;
      return new Promise((resolve) => {
        context.commit("SET_TYPES_STATE", {
          key: "isLoading",
          value: true,
        });
        vm.$http
          .get(
            `tasks/task-types${
              payload && payload.status ? "?status=" + payload.status : ""
            }`
          )
          .then((res) => {
            context.commit("SET_TYPES_STATE", {
              key: "isLoading",
              value: false,
            });
            context.commit("SET_TYPES_STATE", {
              key: "data",
              value: res.data.data,
            });
            resolve(res.data.data);
          })
          .catch((err) => {
            context.commit("SET_TYPES_STATE", {
              key: "isLoading",
              value: false,
            });
            if (!err.accessDenied) {
              resolve(false);
            }
          });
      });
    },
    createTaskType: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .post("tasks/task-types", payload)
          .then((result) => {
            resolve(result.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    updateTaskType: function (_, payload) {
      return new Promise((resolve) => {
        this.$http
          .put(`tasks/task-types/${payload.id}`, payload)
          .then((result) => {
            resolve(result.data.data);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
    deleteTaskType: function (_, id) {
      return new Promise((resolve) => {
        this.$http
          .delete(`tasks/task-types/${id}`)
          .then((res) => {
            Swal.fire({
              title: "Success",
              text: res.data.message,
              icon: "success",
            });
            resolve(true);
          })
          .catch((err) => {
            if (!err.accessDenied) {
              Swal.fire({
                title: "Error",
                text: (err.data || err.response.data).message,
                icon: "error",
              });
            }
            resolve(false);
          });
      });
    },
  },
};
